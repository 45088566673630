<template>
    <div class="quality-course-footer">
        <div class="container wrap-content">
<!--            <div class="footer-info">-->
<!--                <div>江苏京东信息技术有限公司 版权所有 Copyright © All right reserved</div>-->
<!--                <div>江苏京东信息技术有限公司  <a href="" target="_blank" style="color: #898D96" class="site-record">苏ICP备20020434号</a></div>-->
<!--            </div>-->
            <div class="footer-info">
                <div>厦门一课信息技术服务有限公司&nbsp;<a href="https://beian.miit.gov.cn/" target="_blank">闽ICP备15019684号</a></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FooterModule",

    }
</script>

<style scoped lang="scss">
    .quality-course-footer {
        font-size: 12px;
        color: #898D96;
        background: #D2D4D7;
        font-weight: 400;
        line-height: 22px;
        padding: 20px 0;
        width: 100%;
        text-align: center;
        .wrap-content {
            margin: 0 auto;
        }
    }
    .footer-info {
        a {
            font-size: 16px;
            color: #333;
            &:hover {
                color: blue;
            }
        }
    }

</style>
