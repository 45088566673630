<template>
    <el-scrollbar class="home-container" style="height: 100%;" :native="false">
		<div class="banner-blk">
<!--			<div class="fixed-header">-->
<!--				<div class="fixed-content">-->
<!--					<div class="header-logo">-->
<!--						<img src="../../assets/images/jd_course_logo.png" alt="">-->
<!--					</div>-->
<!--					<el-button v-if="isEnter" class="home-login-btn" type="danger" style="width: 70px;height: 40px;" @click="enterCourse">进入</el-button>-->
<!--					<el-dropdown @command="handleLogin" v-if="isLogin">-->
<!--						<el-button class="home-login-btn" type="danger">登录</el-button>-->
<!--						<el-dropdown-menu slot="dropdown" class="home-btns">-->
<!--							<el-dropdown-item command="t">教师登录</el-dropdown-item>-->
<!--							<el-dropdown-item command="s">学生登录</el-dropdown-item>-->
<!--						</el-dropdown-menu>-->
<!--					</el-dropdown>-->
<!--				</div>-->
<!--			</div>-->
	<!--    <div class="home-container">-->
			<div v-swiper:mySwiper="bannerOption" class="home-banner">
				<div class="swiper-wrapper">
					<router-link class="swiper-slide" :to="bannerItem.banner_link" target="_blank" :key="bannerItem.id" v-for="bannerItem in bannerList">
						<img :src="bannerItem.banner_img" class="banner-img">
					</router-link>
				</div>
				<div class="swiper-pagination"></div>
				<div class="banner-swiper-btn-prev">
					<img src="@/assets/icons/left-icon.png" alt="">
				</div>
				<div class="banner-swiper-btn-next">
					<img src="@/assets/icons/right-icon.png" alt="">
				</div>
			</div>
		</div>
        <div class="j-feature-box">
			<div class="news-content">
				<div class="news-header">
					<div class="news-item">
						<hr>
						<h1>新闻中心</h1>
						<hr>
					</div>
					<h3>NEWS</h3>
				</div>
				<div class="news-list">
					<router-link :to="{path: '/qualityCourse/newsCenterDetail', query: {newsId: newsItem.id}}" v-for="(newsItem, newsIndex) in newsList">
						<div class="news-item">
							<div class="news-cover">
								<img :src="newsItem.cover" alt="">
							</div>
							<div class="news-item-body">
								<span class="title">{{newsItem.title}}</span>
								<span class="content">{{newsItem.subtitle}}</span>
							</div>
						</div>
					</router-link>
<!--					<router-link to="/qualityCourse/newsDetailTow">-->
<!--						<div class="news-item">-->
<!--							<img src="@/assets/images/news-2.png" alt="">-->
<!--							<div class="news-item-body">-->
<!--								<span class="title">电子商务专业一体化</span>-->
<!--								<span class="content">理论实践一体化线上线下教学一体化</span>-->
<!--							</div>-->
<!--						</div>-->
<!--					</router-link>-->
<!--					<router-link to="/qualityCourse/newsDetail">-->
<!--						<div class="news-item">-->
<!--							<img src="@/assets/images/news-1.png" alt="">-->
<!--							<div class="news-item-body">-->
<!--								<span class="title">京东机器人 教育学院</span>-->
<!--								<span class="content">"职教20条"深化职业教育改革，支持校企合作突破</span>-->
<!--							</div>-->
<!--						</div>-->
<!--					</router-link>-->
<!--					<router-link to="/qualityCourse/newsDetailOne">-->
<!--						<div class="news-item">-->
<!--							<img src="@/assets/images/news-3.png" alt="">-->
<!--							<div class="news-item-body">-->
<!--								<span class="title">职业技能提升行动方案</span>-->
<!--								<span class="content">今日，国务院办公厅印发《职业技能提升行动方案（2019-2021）》</span>-->
<!--							</div>-->
<!--						</div>-->
<!--					</router-link>-->
<!--					<router-link to="/qualityCourse/newsDetailThree">-->
<!--						<div class="news-item">-->
<!--							<img src="@/assets/images/news-5.jpg" alt="">-->
<!--							<div class="news-item-body">-->
<!--								<span class="title">【JDSEC】政校企“协同育人基地”成立</span>-->
<!--								<span class="content">热烈祝贺政校企“协同育人基地”成立暨宿迁开放大学京东电商学院冬季职场体验月总结表彰大会圆满成功！</span>-->
<!--							</div>-->
<!--						</div>-->
<!--					</router-link>-->
					<!--<div class="news-items">-->
					<!--<img src="@/assets/images/news-4.png" alt="">-->
					<!--<div class="news-item-body">-->
					<!--<span class="title">敬请期待</span>-->
					<!--<span class="content">...</span>-->
					<!--</div>-->
					<!--</div>-->
				</div>
			</div>
			<div class="course-design-content main-primary">
				<div class="main-center">
					<div class="course-design-img">
						<img src="../../assets/home-img/form@2x.png" alt="">
					</div>
					<div class="course-design-content">
						<div class="cd-title">
							<h4>一体化课程设计</h4>
							<p>理论实践一体化线上线下教学一体化</p>
						</div>
<!--						<div class="cd-btn">-->
<!--							<el-button type="danger" class="home-primary-btn">课程介绍<i class="el-icon-right"></i></el-button>-->
<!--						</div>-->
					</div>
				</div>
			</div>
			<div class="online-shop main-primary">
				<div class="main-center">
					<div class="online-shop-content">
						<div class="cd-title">
							<h4>全景式电商内容</h4>
							<p>覆盖电商基础、电商引流、电商运营、数据分析、仓储物流等模块</p>
						</div>
<!--						<div class="cd-btn">-->
<!--							<el-button type="danger" class="home-primary-btn">电商内容介绍<i class="el-icon-right"></i></el-button>-->
<!--						</div>-->
					</div>
					<div class="online-shop-img">
						<img src="../../assets/home-img/survey@2x.png" alt="">
					</div>
				</div>
			</div>
			<div class="main-primary res">
				<div class="main-center">
					<div class="course-design-img">
						<img src="../../assets/home-img/form@2x.png" alt="">
					</div>
					<div class="course-design-content">
						<div class="cd-title">
							<h4>多元化资源形式</h4>
							<p>微课、课件、讲稿、案例、实训要求、拓展资料等应有尽有</p>
						</div>
<!--						<div class="cd-btn">-->
<!--							<el-button type="danger" class="home-primary-btn">立即前往体验<i class="el-icon-right"></i></el-button>-->
<!--						</div>-->
					</div>
				</div>
			</div>

        </div>
<!--    </div>-->
        <FooterModule></FooterModule>
    </el-scrollbar>
</template>

<script>
    import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'
    import FooterModule from 'components/qualityCourseLayout/FooterModule';
    export default {
        data() {
            return {
                isLogin: true,
                isEnter: false,
                bannerList: [],
                bannerOption: {
                    autoplay: {
                        delay: 3000,
                        disableOnInteraction: false
                    },
					initialSlide: 1,
					autoHeight: true,
                    loop: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    navigation: {
                        nextEl: '.banner-swiper-btn-next',
                        prevEl: '.banner-swiper-btn-prev',
                    },
                    observer: true,
                    observeParents: true,
                },
				newsList: [],
            }
        },
        components: {
            Swiper,
            SwiperSlide,
            FooterModule,
        },
        directives: {
            swiper: directive
        },
        created() {
            if (localStorage.getItem('sUsername')) {
                this.isLogin = false;
                this.isEnter = true;
            } else {
                this.isLogin = true;
                this.isEnter = false;
            }
        },
		mounted() {
        	this.getBannerList();
			this.getNewsList();
		},
		methods: {
			getNewsList() {
				let params = {
					delivery: 1,
					limit: 4,
					page: 1,
				}
				this.$http.axiosGetBy(this.$api.newsList, params, (res) => {
					if (res.code === 200) {
						this.newsList = res.data
					}
				}, (err) => {
					console.log(err)
				})
			},
        	getBannerList() {
				this.$http.axiosGet(this.$api.bannerList, (res) => {
					if (res.code === 200) {
						this.bannerList = res.data
					}
				}, (err) => {
					console.log(err)
				})
			},
			handleLogin(command) {
				if (command === 't') {
					this.$router.push('/user/teacherLogin');
				} else if (command === 's') {
					this.$router.push('/user/login');
				}
			},
            enterCourse() {
                this.$router.push('/qualityCourse/index');
			}
		}
    }
</script>

<style scoped lang="scss">
	.home-btns {
		.el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover {
			background-color: rgb(253, 226, 226);
			color: #F56C6C;
		}
	}
	.banner-blk {
		position: relative;
	}
	/*.fixed-header {*/
	/*	!*position: absolute;*!*/
	/*	height: 4.5rem;*/
	/*	width: 100%;*/
	/*	top: 0.8125rem;*/
	/*	left: 0;*/
	/*	display: flex;*/
	/*	justify-content: center;*/
	/*	align-items: center;*/
	/*	z-index: 999;*/
	/*	.fixed-content {*/
	/*		width: 1200px;*/
	/*		height: 100%;*/
	/*		display: flex;*/
	/*		justify-content: space-between;*/
	/*		align-items: center;*/
	/*		.header-logo {*/
	/*			height: 100%;*/
	/*			img {*/
	/*				width: auto;*/
	/*				height: 100%;*/
	/*			}*/
	/*		}*/
	/*		.home-login-btn {*/
	/*			height: 100%;*/
	/*			background-color: #E21D1A;*/
	/*			border-color: #E21D1A;*/
	/*		}*/
	/*	}*/
	/*}*/
    .home-container {
        /*height: calc(100% - 84px) !important;*/
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .home-banner {
        width: 100%;
        .banner-img {
            display: block;
            width: 100%;
            max-height: 640px;
        }
        ::v-deep .swiper-pagination-bullets {
            .swiper-pagination-bullet {
                width: 30px;
                height: 3px;
                background: #fff;
                border-radius: 0;
            }
        }
        .banner-swiper-btn-prev,
        .banner-swiper-btn-next {
            width: 15%;
            position: absolute;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 10;
            opacity: .5;
            cursor: pointer;
            img {
                display: block;
                width: 20px;
            }
            &:hover {
                opacity: 1;
            }
        }
        .banner-swiper-btn-prev {
            left: 0;
        }
        .banner-swiper-btn-next {
            right: 0;
        }
    }
    .j-feature-box {
        /*margin: 0px auto 85px auto;*/
        margin: 0 auto;
        .j-feature-title {
            position: relative;
            display: block;
            width: 120px;
            margin: 0 auto;
            font-size: 26px;
            color: #333;
            text-align: center;
            &:after {
                content: '';
                position: absolute;
                height: 2px;
                background: #EB4D4B;
                bottom: -12px;
                left: 10px;
                right: 10px;
            }
        }
        .j-feature-list {
            margin-top: 50px;
            display: flex;
            justify-content: center;
            .j-feature-content {
                width: 370px;
                height: 267px;
                display: block;
                box-shadow: 0px 3px 10px 0px rgba(205,199,204,1);
                border-radius: 5px;
                padding: 42px 37px;
                margin: 0 22px;
                box-sizing: border-box;
                &.bg1 {
                    background: url("../../assets/images/feature-bg1.png") no-repeat 0 0;
                }
                &.bg2 {
                    background: url("../../assets/images/feature-bg2.png") no-repeat 0 0;
                }
                &.bg3 {
                    background: url("../../assets/images/feature-bg3.png") no-repeat 0 0;
                }
            }
        }
		.main-primary {
			width: 100%;
			height: 400px;
			display: flex;
			justify-content: center;
			align-items: center;
			.main-center {
				width: 970px;
				height: 270px;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				.course-design-img {
					height: 100%;
					width: 376px;
					img {
						width: 100%;
						height: 100%;
					}
				}
				.course-design-content {
					margin-left: 140px;
					height: 100%;
					.cd-title {
						margin-top: 39px;
						h4 {
							font-size: 29px;
							margin: 0;
							padding: 0;
							color: #333333;
							font-family: Microsoft Yahei,Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
						}
						p {
							margin-top: 21px;
							color: #666;
							font-size: 16px;
						}
					}
				}
				.cd-btn {
					margin-top: 63px;
					.home-primary-btn {
						background-color: #E31D1A;
						border-color: #E31D1A;
						padding: 12px 30px;
						box-shadow: 4px 2px 12px #910806;
					}
				}
			}
			&.online-shop {
				background-color: #F3F3F6;
				.main-center {
					justify-content: space-between;
					.online-shop-content {
						.cd-title {
							h4 {
								font-size: 29px;
								margin: 0;
								padding: 0;
								color: #333333;
								font-family: Microsoft Yahei,Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
							}
							p {
								margin-top: 21px;
								color: #666;
								font-size: 16px;
							}
						}
						
					}
					.online-shop-img {
						width: 376px;
						height: 282px;
						img {
							width: 100%;
							height: 100%;
						}
						
					}
				}
			}
		}
    }

	.news-content {
		background-color: #F3F3F6;
		padding: 0 240px 30px;
		display: flex;
		flex-direction: column;
		width: 100%;
		box-sizing: border-box;
		.news-header {
			width: 100%;
			background-color: #F3F3F6;
			margin: 0 auto;
			.news-item {
				display: flex;
				justify-content: center;
				align-items: center;
				hr {
					border-color: #3A9AFA;
					width: 50px;
					margin: 0;
				}
				h1 {
					/*font-size: 48px;*/
					font-size: 30px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #333333;
					text-align: center;
					/*margin: 60px 20px;*/
					margin: 30px 20px;
				}
			}
			h3 {
				font-size: 24px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: rgba(102,102,102,1);
				text-align: center;
				margin: 0;
				margin-top: -30px;
			}
		}
		.news-list {
			background-color: #F3F3F6;
			margin: 20px;
			overflow: hidden;
			display: flex;
			a {
				width: calc(25% - 20px);
				margin: 0 10px;
				flex-shrink: 0;
				box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.16);
			}
			.news-item {
				display: flex;
				flex-direction: column;
				width: 100%;
				background: rgba(238,238,238,1);
				.news-cover {
					width: 100%;
					height: 0;
					padding-bottom: 60.97%;
					background: #fff;
					position: relative;
					overflow: hidden;
					img {
						max-height: 200px;
						max-width: 100%;
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
					}
				}
				.news-item-body {
					display: flex;
					flex-direction: column;
					padding: 15px;
					flex: 1;
					color: #333;
					.title {
						text-align: center;
						display: block;
						font-size: 18px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: rgba(51,51,51,1);
						margin-bottom: 10px;
						height: 54px;
						word-break: break-all;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						overflow: hidden;
					}
					.content {
						height: 80px;
						overflow : hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 4;
						-webkit-box-orient: vertical;
					}
				}
			}
			.news-items {
				position: relative;
				width: calc(25% - 20px);
				height: 369px;
				box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.16);
				img {
					position: absolute;
					width: 100%;
					height: 100%;
				}
				.news-item-body {
					position: absolute;
					z-index: 9;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					height: 100%;
					width: 100%;
					.title {
						font-size:36px;
						font-family:Source Han Sans CN;
						font-weight:400;
						color:rgba(255,255,255,1);
					}
					.content {
						font-size:36px;
						font-family:Source Han Sans CN;
						font-weight:400;
						color:rgba(255,255,255,1);
					}
				}
			}
		}
	}
</style>